<template>
	<div>

		<div class="grid grid-cols-2 mb-4">
			<div class="col-span-1">
				<h2 class="text-2xl font-bold">All Expenses</h2>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for employee’s name, status, etc."
						v-model="activities.query">
					<ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
				</div>
			</div>
		</div>

		<div class="border border-solid border-blue-200 overflow-hidden">
			<datatable :data="activities.data" :columns="activities.columns" :loading="activities.loading" :query="activities.query" ref="table" />
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				activities: this.$options.resource([], {
					columns: [
						{
							className: 'w-1/10',
							name: 'approved',
							th: '',
							sortable: false,
							render: () => {
								// return `
								// 	<div class="w-20px h-20px rounded-50 inline-flex items-center justify-center bg-green-500">
								// 		<ion-icon name="checkmark-sharp" class="text-white" />
								// 	</div>
								// `;
								return '<div class="w-20px h-20px rounded-50 border border-blue-200"></div>';
							}
						},
						{
							name: 'user',
							th: 'Name',
						},
						{
							name: 'department',
							th: 'Department',
						},
						{
							name: 'amount',
							th: 'Amount',
						},
						{
							className: 'w-1/10',
							name: 'status',
							th: 'Status',
							render: () => {
								return `
									<button class="btn btn-green mr-2">Approve</button>
									<button class="btn btn-red-outline">Dispute</button>
								`;
							}
						},
					]
				}, {
					query: ''
				}),
			}
		},
		beforeMount() {
			// this.getActivities();
		},
		methods: {
			async getActivities() {
				this.activities.loading = true;

				await this.$get({
					url: `${this.$baseurl}/companies/activities/${this.user.company_id}`,
					headers: this.headers,
					success: response => {
						this.activities.setValue(response.data.data);
						this.activities.loaded = true;
					},
					error: error => {
						this.activities.error = error;
					}
				});

				this.activities.loading = false;
			}
		}
	}
</script>